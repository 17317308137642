import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


export default function Snackbars(props) {
  const [open, setOpen] = React.useState(props.open_close);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar className="fixbar-message"
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={open}
        onClose={handleClose}
        // autoHideDuration={3000}
      >
        <Alert
          onClose={handleClose}
          severity={props.type}
          sx={{ width: '100%' }} 
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  )
}
