import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import App from './layout/App';
import reducer from './store/reducer';
import config from './config';
import './assets/scss/style.scss';
import i18next from 'i18next';
import language from './language/language.json'
const store = createStore(reducer);

i18next.init({
    lng: 'en', 
    debug: false,
    resources: {
      en: {
        translation: language["en"]
      },
      sl: {
        translation:language["sl"]
      },
      cz: {
        translation:language["cz"]
      },
      rus: {
        translation:language["rus"]
      },
      grm: {
        translation:language["grm"]
      },
      sp: {
        translation:language["sp"]
      },
      hg: {
        translation:language["hg"]
      },
      gr: {
        translation:language["gr"]
      }
    }
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);