import { combineReducers } from 'redux';
import userReducer from '../reducer/userReducer';
import customizationReducer from './customizationReducer';

const reducer = combineReducers({
    customization: customizationReducer,
    usersdata: userReducer,
});

export default reducer;
