import NavigationOutlinedIcon from '@material-ui/icons/NavigationOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import FormatUnderlinedOutlinedIcon from '@material-ui/icons/FormatUnderlinedOutlined';
import GamesOutlinedIcon from '@material-ui/icons/GamesOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import FilterVintageOutlinedIcon from '@material-ui/icons/FilterVintageOutlined';
import FormatColorTextOutlinedIcon from '@material-ui/icons/FormatColorTextOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import EditAttributesOutlinedIcon from '@material-ui/icons/EditAttributesOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import TranslateIcon from '@material-ui/icons/Translate';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import MailOutlineIcon from '@material-ui/icons/MailOutline';


import dashboard_img from './assets/images/menuicon/dashboard.png';
import account_img from './assets/images/menuicon/account.png';
import profile_img from './assets/images/menuicon/profile.png';
import transactions_img from './assets/images/menuicon/transactions.png';
import message_img from './assets/images/menuicon/message.png';
import reports_img from './assets/images/menuicon/reports.png';

import arrow_img from './assets/images/menuicon/arrow.png';


const icons = {
    NavigationOutlinedIcon: NavigationOutlinedIcon,
    HomeOutlinedIcon: HomeOutlinedIcon,
    FormatUnderlinedOutlinedIcon: FormatUnderlinedOutlinedIcon,
    GamesOutlinedIcon: GamesOutlinedIcon,
    LoyaltyOutlinedIcon: LoyaltyOutlinedIcon,
    ForumOutlinedIcon: ForumOutlinedIcon,
    DescriptionOutlinedIcon: DescriptionOutlinedIcon,
    GridOnOutlinedIcon: GridOnOutlinedIcon,
    TableChartOutlinedIcon: TableChartOutlinedIcon,
    AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
    CallToActionOutlinedIcon: CallToActionOutlinedIcon,
    NotificationsNoneOutlinedIcon: NotificationsNoneOutlinedIcon,
    AppsOutlinedIcon: AppsOutlinedIcon,
    SmsOutlinedIcon: SmsOutlinedIcon,
    FilterVintageOutlinedIcon: FilterVintageOutlinedIcon,
    FormatColorTextOutlinedIcon: FormatColorTextOutlinedIcon,
    ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
    LayersOutlinedIcon: LayersOutlinedIcon,
    BlockOutlinedIcon: BlockOutlinedIcon,
    ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
    FiberManualRecordOutlinedIcon: FiberManualRecordOutlinedIcon,
    EditAttributesOutlinedIcon: EditAttributesOutlinedIcon,
    FaceOutlinedIcon: FaceOutlinedIcon,
    RadioButtonUncheckedOutlinedIcon: RadioButtonUncheckedOutlinedIcon,
    QuestionAnswerOutlinedIcon: QuestionAnswerOutlinedIcon,
    AccountCircleOutlinedIcon: AccountCircleOutlinedIcon,
    ShoppingCartOutlinedIcon: ShoppingCartOutlinedIcon,
    MailOutlineRoundedIcon: MailOutlineRoundedIcon,
    SpeakerNotesOutlinedIcon: SpeakerNotesOutlinedIcon,
    CardGiftcardOutlinedIcon: CardGiftcardOutlinedIcon,
    ErrorOutlineRoundedIcon: ErrorOutlineRoundedIcon,
    HourglassEmptyRoundedIcon: HourglassEmptyRoundedIcon,
    MonetizationOnOutlinedIcon: MonetizationOnOutlinedIcon,
    AssignmentIndOutlinedIcon: AssignmentIndOutlinedIcon,
    SecurityOutlinedIcon: SecurityOutlinedIcon,
    HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
    TranslateIcon: TranslateIcon,
    FormatListNumberedRtlIcon: FormatListNumberedRtlIcon,
    MailOutlineIcon: MailOutlineIcon,


};

export default {
    items: [
        {
            id: 'navigation',
            title: '',
            caption: '',
            type: 'group',
            icon: icons['NavigationOutlinedIcon'],
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    icon: dashboard_img,
                    url: '/dashboard',
                },
                {
                    id: 'account',
                    title: 'Account',
                    type: 'collapse',
                    icon: account_img,
                    url: '',
                    children: [
                        // {
                        //     id: 'ibanaccount',
                        //     title: 'Account',
                        //     type: 'item',
                        //     icon: arrow_img,
                        //     url: '/Account',
                        // },
                        // {
                        //     id: 'card',
                        //     title: 'Card',
                        //     type: 'item',
                        //     icon: arrow_img,
                        //     url: '/cardmanagement',
                        // },
                        {
                            id: 'accountmainpage',
                            title: 'Account',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Accountboxes',
                        },
                        {
                            id: 'creditaccount',
                            title: 'Credit account',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Accountboxes_credit_account',
                        },
                        {
                            id: 'cryptoaccount',
                            title: 'Crypto account',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Accountboxes_crypto_account',
                        },
                        {
                            id: 'earningaccount',
                            title: 'Earning account',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Accountboxes_earning_account',
                        },
                        {
                            id: 'cashaccount',
                            title: 'Cash account',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Accountboxes_cash_account',
                        },
                        {
                            id: 'dashboardnew',
                            title: 'Dashboard',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Dashboard_new',
                        },
                    ],
                },
                {
                    id: 'transactions',
                    title: 'Transactions',
                    type: 'collapse',
                    icon: transactions_img,
                    url: '',
                    children: [
                        // {
                        //     id: 'transfersbetweenaccounts',
                        //     title: 'Transfers between my accounts',
                        //     type: 'item',
                        //     icon: arrow_img,
                        //     url: '/Transferbetweenaccounts',
                        // },
                        // {
                        //     id: 'transferbetweenusers',
                        //     title: 'Transfer between MB users',
                        //     type: 'item',
                        //     icon: arrow_img,
                        //     url: '/Transferbetweenusers',
                        // },
                        // {
                        //     id: 'outgoingsepatransfer',
                        //     title: 'Outgoing SEPA transfer',
                        //     type: 'item',
                        //     icon: arrow_img,
                        //     url: '/Outgoingwiretransfer',
                        // },
                        // {
                        //     id: 'incomingsepatransfer',
                        //     title: 'Incoming SEPA transfer',
                        //     type: 'item',
                        //     icon: arrow_img,
                        //     url: '/Incomingwiretransfer',
                        // },
                        // {
                        //     id: 'cardfunding',
                        //     title: 'Card funding',
                        //     type: 'item',
                        //     icon: arrow_img,
                        //     url: '/Cardfundingtransfer',
                        // },
                        {
                                id: 'transactions',
                                title: 'Transactions',
                                type: 'item',
                                icon: arrow_img,
                                url: '/Transactions_box',
                        },
                        {
                            id: 'cryptoexchange',
                            title: 'Crypto exchange',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Transactions_crypto_exchange',
                        },
                        {
                            id: 'cashorder',
                            title: 'Cash order',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Transactions_cash_order',
                        },
                        {
                            id: 'cryptoearning',
                            title: 'Crypto earning',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Transactions_crypto_earning',
                        },
                        {
                            id: 'cryptodeposit',
                            title: 'Crypto deposit',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Transactions_crypto_deposit',
                        },
                        {
                            id: 'cryptowithdrawal',
                            title: 'Crypto withdrawal',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Transactions_crypto_withdraw',
                        },
                        {
                            id: 'credit',
                            title: 'Credit',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Transactions_crypto_credit',
                        },
                    ],
                },
                {
                    id: 'reports',
                    title: 'Reports',
                    type: 'collapse',
                    icon: reports_img,
                    url: '',
                    children: [
                        {
                            id: 'allaccountstransactions',
                            title: 'Reports',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Allaccountstransactions',
                        },
                    ],
                },
                {
                    id: 'profile',
                    title: 'Profile',
                    type: 'collapse',
                    icon: profile_img,
                    url: '',
                    children: [
                        {
                            id: 'personalcompanyinfo',
                            title: 'Summary',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Summary',
                        },
                        {
                            id: 'editprofile',
                            title: 'Edit Profile',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Editprofile',
                        }, 
                        {
                            id: 'kyc',
                            title: 'Verification Level',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Kyc',
                        },
                        {
                            id: 'changepassword',
                            title: 'Change Password',
                            type: 'item',
                            icon: arrow_img,
                            url: '/changepassword',
                        },
                        {
                            id: 'security',
                            title: 'Security',
                            type: 'item',
                            icon: arrow_img,
                            url: '/TwoSecurity',
                        },
                        {
                            id: 'settings',
                            title: 'Settings',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Settings',
                        },
                        {
                            id: 'files',
                            title: 'Files',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Files',
                        },
                    ],
                },
                {
                    id: 'message',
                    title: 'Message',
                    type: 'collapse',
                    icon: message_img,
                    url: '',
                    children: [
                        {
                            id: 'support_chat',
                            title: 'Support chat',
                            type: 'item',
                            icon: arrow_img,
                            url: '/Incomingmessage',
                        },
                        {
                            id: 'news',
                            title: 'News',
                            type: 'item',
                            icon: arrow_img,
                            url: '/News',
                        },
                    ],
                },
            ],
        },


    ],
};
